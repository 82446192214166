import StockOrder from "./views/StockOrder";
import Deliveries from "./views/Deliveries";
import Purchases from "./views/Purchases";
import Sales from "./views/Sales";
import Products from "./views/Products";
import SalesN2 from "./views/SalesN2";
import OrderN2 from "./views/OrderN2";
import Deliveries2 from "./views/Deliveries2";
import Rmas from "./views/Rmas";
import StockGeneral from "./views/StockGeneral";
import PanelDelivery from "./views/PanelDelivery";
import Stickers from "./views/Stickers";
import Employees from "./views/Employees";
import Info from "./views/Info";
import Roles from "./config/roles";
import Orders from "./views/Orders";
import StateOrders from "./views/StateOrders";

const routes = [
    { path: '/ubicaciones', name: 'Ubicaciones', element: Products, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
    { path: '/reponer-inmediato', name: 'Necesidad inmediata', element: StockOrder, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role] },
    { path: '/reponer-general', name: 'Reposición general', element: StockGeneral, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role] },
    { path: '/rma/:warehouse', name: 'Rmas almacen', element: Rmas, roles: [Roles.ADMIN.role, Roles.SAT.role] },
    { path: '/rutas', name: 'Rutas', element: Deliveries, roles: [Roles.ADMIN.role] },
    { path: '/compras', name: 'Compras', element: Purchases, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role] },
    { path: '/ventas', name: 'Ventas', element: Sales, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role] },
    { path: '/pedidosn2', name: 'Pedidos nave 2', element: SalesN2, roles: [Roles.ADMIN.role, Roles.ALMACEN.role, Roles.REPONEDOR.role] },
    { path: '/pedidosn2/order/:order', name: 'Orden nave 2', element: OrderN2, roles: [Roles.ADMIN.role, Roles.ALMACEN.role, Roles.REPONEDOR.role] },
    { path: '/rutas2', name: 'Alta ruta', element: Deliveries2, roles: [Roles.ADMIN.role, Roles.SAT.role] },
    { path: '/panel', name: 'Sacar etiqueta', element: PanelDelivery, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
    { path: '/panel/etiquetas/:delivery', name: 'Etiquetas -', element: Stickers, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
    { path: '/empleados', name: 'Empleados', element: Employees, roles: [Roles.ADMIN.role] },
    { path: '/panel/rutas/:delivery', name: 'Punteo ruta', element: Deliveries, roles: [Roles.ADMIN.role,Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
    { path: '/info', name: 'Info pedidos', element: Info, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
    { path: '/estados', name: 'Pasar a preparación', element: Orders, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role] },
    { path: '/listado-estados', name: 'Estados pedidos', element: StateOrders, roles: [Roles.ADMIN.role] },
    { path: '/listado-estados/info', name: 'Info albaran', element: Info, roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role] },
]

export default routes